import React from 'react';
import Helmet from 'react-helmet';

import Section from '@latitude/section';
import { Link } from '@latitude/link';
import { BREAKPOINT, BUTTON_STYLE } from '@latitude/core/utils/constants';
import Layout from '@/components/layout';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import heroImage from '../images/hero-djcard1.webp';

const buttonHref = '/credit-cards/david-jones/addcard/achform/';

function DavidJonesAchPage(props) {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.latitudefinancial.com.au/credit-cards/david-jones/ach/"
          />
          <meta
            name="description"
            content="Add an additional cardholder to your David Jones credit card"
          />
          <title>Additional Card Holder | David Jones credit card</title>
        </Helmet>

        <div
          className="pageHeader"
          css={`
            && section.lfs-pageheader {
              background: rgb(0, 0, 0);
            }
            @media (min-width: ${BREAKPOINT.LG}) {
              && div.lfs-pageheader-hero > div {
                max-height: 400px;
              }
              && div.HeroContent {
                padding: 0 0;
              }
            }
          `}
        >
          <PageHeaderSection
            title="David Jones additional cardholder"
            subTitle="Help them experience a credit card like no other."
            pageImage={heroImage}
            pageImageAlt="David Jones cards"
          >
            <div className="row text-white">
              <div className="col-6 col-md-4 text-left text-left">
                <Link
                  button={BUTTON_STYLE.TERTIARY_INVERSE}
                  css={{
                    margin: '0 auto',
                    width: 'fit-content'
                  }}
                  href={buttonHref}
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </PageHeaderSection>
        </div>

        <Section>
          <h1 css={{ color: '#000' }}>
            Share your benefits with an additional cardholder
          </h1>
          <p>
            Adding an Additional Cardholder on your account can help provide
            extra security for emergencies or help with your life admin. As the
            account holder, you're still responsible for making repayments and
            any outstanding balances on your account.
          </p>
          <p>
            Your additional cardholder can make purchases and request cash
            advances, but they won't be able to register or sign in to your
            account online via the website and mobile app or receive any
            statements. You will also need to activate your card before they can
            activate theirs.
          </p>
          <p>
            It's a good idea to talk to your additional cardholder about how
            you'd like the card to be used. With some good habits in place, you
            can both enjoy the benefits of the David Jones credit card. Speaking
            of good habits, set up a direct debit for repayments to avoid late
            fees if you haven't already.
          </p>
          <p>Before you complete the online form, please have handy:</p>
          <p>
            <ul>
              <li>
                Your account number &mdash; this can be found on your statement
                and your application approval email
              </li>
              <li>
                Additional Cardholder's full name &mdash; as it appears on their
                ID
              </li>
              <li>
                Additional Cardholder's date of birth &mdash; make sure your
                nominated additional cardholder is an Australian resident and
                over 16 years of age
              </li>
              <li>
                Additional Cardholder's email address &mdash; so we can contact
                them to complete the request
              </li>
            </ul>
          </p>
          {buttonHref && (
            <>
              <p>
                Simply complete the <a href={buttonHref}>online form</a> and
                you're one step closer to sharing the benefits of your David
                Jones credit card!
              </p>
              <a
                href={buttonHref}
                css={`
                  margin: 0 auto;
                  width: fit-content;
                  border: 1px solid #000000;
                  border-radius: 4px;
                  background-color: #000000;
                  color: #fff;
                  text-align: center;
                  text-shadow: none !important;
                  padding: 10pt 20pt;
                  white-space: nowrap;

                  &&:hover {
                    border: 1px solid #000000;
                    background-color: #fff;
                    color: #000000;
                  }
                `}
              >
                Apply Now
              </a>
            </>
          )}
        </Section>
      </main>
    </Layout>
  );
}

export default DavidJonesAchPage;
